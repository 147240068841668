.MenuLeft{
  width: 16.25%;
  height: 250px;
  position: absolute;
  left: 0; top: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
}

  .MenuLeft__Link{
    width: 70%;
    height: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    cursor: pointer;
    transition: .7s;
    z-index: 5;
    margin-top: 5px;
  }

  .MenuLeft__Link::before{
    content: "";
    width: 0px;
    height: 100%;
    background: var(--ColorMain);
    margin-right: 5px;
    transition: .3s;
  }
  .MenuLeft__Link:hover::before{
    width: 5px;
    transition: .3s;
  }

  