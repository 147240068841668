.Video{
  width: 100%;
  height: 1200px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  position: relative;

  .VideoBlock{
    width: 70%;
    height: 50%;

    .Video{
      height: 100%;
    }
  }

  .TextBlock{
    width: 100%;
    height: 60%;
    position: absolute;
    top: 30%;
    display: flex;
    justify-content: center;
    align-content: space-between;
    flex-wrap: wrap;
    
    //Parrafos
    .Paragraph{
      width: 100%;
      height: 33%;
      overflow: hidden;

      .ParagraphAnimated{
        width: auto;
        height: 100%;
        display: flex;
        justify-self: flex-start;
        align-items: center;
        font-size: 195px;
        font-weight: bolder;
        text-transform: capitalize;
        opacity: 1;
        mix-blend-mode: darken;
        text-transform: uppercase;
        color: var(--ColorMain);
        text-overflow: clip;
        white-space: nowrap;
      }
      #FirstParahraph{
        transform: translateX(-100%);
      }
      
      #ThirdParahraph{
        transform: translateX(-100%);
      }
    }
  }
}

.MarqueeLeftFirst{
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes marquee2 {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-200%);
  }
}