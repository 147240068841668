:root{
  --ColorMain:#ec1c24;
  --SecondaryColor:#3C3C3C;
}
*{
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  color:var(--SecondaryColor);
}
body{
  margin: 0;
  padding: 0;
}
.App{
  width: 100%;
  height: 100vh;
}

/**Botones*/

.Btn__Rounded{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: solid 2px;  
  display: flex;
  justify-content: center;
  align-items: center;  
  color: var(--ColorMain);
  margin-top: 15px;
  cursor: pointer;
  font-size: 25px;
}

/*
@media(max-width:650px){
  .HideOnMovil{
    display: none !important;
  }
}
*/