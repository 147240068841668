.Info1{
  width: 100%;
  height: 1500px;
  padding: 0px 5%;

  display: grid;
  grid-template-columns:  48%  50%;
  grid-template-rows: 1fr 1fr 1fr;
  column-gap: 2%;
  row-gap: 5%;
  align-self: flex-start;
  align-items: flex-start;

  .Info, .Info2{
    grid-column-start:2;
    display: flex;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    //Titulo
    .Title{
      width: 70%;
      text-align: left;
      text-transform: uppercase;
      font-size: 40px;
      font-weight:400;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      letter-spacing: 2px;

      &__Line{
        width: auto;
        height: auto;
        margin-right: 5px;
        position: relative;
        overflow: hidden;

        &--Red{
          color: var(--ColorMain);
          position: relative;
        }
      }
    }

    //Parrrafo
    .Text{
      width: 60%;
      margin-right: 10%;
      height: auto;
      margin-top: 25px;
      overflow: hidden;
      
      &__Content{
        font-size: 20px;
        line-height: 150%;
        transform: translateY(100%);
        opacity: 0;
        position: relative;
        
        
      }
    }
  }

  .Info2{
    grid-column-start:1;
    margin-top: 150px;

    .Text{
      &__Content{
        opacity: 1;
        transform: none;
      }
    }
  }

  .Image{
    grid-column-start:1;
    overflow: hidden;
    margin-top: 100px;
    .Image1{
      width: 100%;
      opacity: 0;
      transform: translateY(100%);
    }
  }

  .ImageRigth{
    grid-column-start:2;
    grid-row-start: 0;
    overflow: hidden;
    .Image2{
      width: 100%;
    }
    
  }


}

//Animaciones
#TextAnimation1{
  width: 100%;
  height: 100%;
  position: absolute;
  background: var(--ColorMain);
  z-index: 1;
}
.image2--margin{
  margin-top: 100px;
}
.Text3Especial{
  margin-left: 50%;
}
#TextAnimation3{
  width: 100%;
  height: 100%;
  position: absolute;
  background: var(--ColorMain);
  z-index: 1;
  top: 0;
}
