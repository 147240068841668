.Banner{
  width: 100%;
  height: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  margin-top: 100px;

  .ImageContainer{
    width: 70%;
    height: 100%;
    z-index: 0;
    overflow: hidden;

    &__Img{
      width: 100%;
    }
  }

  .TextContainer{
    width: 95%;
    height: auto;
    z-index: 1;
    position: absolute;
    
    color: var(--ColorMain);
    text-transform: uppercase;
    font-size:200px;
    text-align: center;
    font-weight: 600;
    letter-spacing: 15px;
    mix-blend-mode: darken;
  }
}