.Author{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: column;


  &__Info{
    width: 550px;
    height: auto;
    text-align: center;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 130%;
    letter-spacing: 3px;

    &--Red{
      color:var(--ColorMain)
    }
  }

  &__About{
    width: 550px;
    text-align: center;
    font-size:22px;
    margin-top: 25px;
    letter-spacing: 1px;
  }

  &__Mini{
    width: 550px;
    text-align: center;
    font-size:16px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
}