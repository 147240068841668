.Hero{
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  .Images{
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    &__Image{
      width: 16.25%;
      height: 100%;
      overflow: hidden;
      img{
        width: 100%;
      }
    }

    
  }

  .Description{
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    .Text{
      width: 67.5%;
      font-size: 43px;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-left: 16.25%;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: flex-start;
      flex-direction: column;
      &--Red{
        color: var(--ColorMain);
      }
      &--Title{
        background: var(--ColorMain);
        color: white;
        font-size: 17px;
        padding: 5px;
        font-weight: 300;
        letter-spacing: 4px;
        margin-bottom: 25px;
      }
    }

    .Image{
      width:  16.25%;
      height: 100%;
      overflow: hidden;
    }
  }
}